export const getLocalStorage = key => {
  const now = new Date();
  const item = JSON.parse(localStorage.getItem(key));

  if (!item) {
    return null;
  }

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);

    return false;
  }

  return item.value;
};

export const setLocalStorage = id => {
  const now = new Date();
  const item = { value: 'true', expiry: now.getTime() + 1209600000 };

  localStorage.setItem(`exit_intent_seen_${id}`, JSON.stringify(item));
};
